(function($) {
  var d = new Date(new Date().getTime());
  var t = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
  var today = d.getDate() + "/" + ('0' + (d.getMonth() + 1)).slice(-2) + "/" + d.getFullYear();
  var tomorrow = t.getDate() + "/" + ('0' + (t.getMonth() + 1)).slice(-2) + "/" + t.getFullYear();

  $.fn.datepicker.dates['nl'] = {
    days: ["zondag", "maandag", "dinsdag", "woensdag", "donderdag", "vrijdag", "zaterdag"],
    daysShort: ["zo", "ma", "di", "wo", "do", "vr", "za"],
    daysMin: ["zo", "ma", "di", "wo", "do", "vr", "za"],
    months: ["januari", "februari", "maart", "april", "mei", "juni", "juli", "augustus", "september", "oktober", "november", "december"],
    monthsShort: ["jan", "feb", "mrt", "apr", "mei", "jun", "jul", "aug", "sep", "okt", "nov", "dec"],
    today: "Vandaag",
    monthsTitle: "Maanden",
    clear: "Wissen",
    weekStart: 1,
    format: "dd-mm-yyyy"
  };

  $.fn.datepicker.dates['fr'] = {
    days: ["dimanche", "lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi"],
    daysShort: ["dim.", "lun.", "mar.", "mer.", "jeu.", "ven.", "sam."],
    daysMin: ["d", "l", "ma", "me", "j", "v", "s"],
    months: ["janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"],
    monthsShort: ["janv.", "févr.", "mars", "avril", "mai", "juin", "juil.", "août", "sept.", "oct.", "nov.", "déc."],
    today: "Aujourd'hui",
    monthsTitle: "Mois",
    clear: "Effacer",
    weekStart: 1,
    format: "dd/mm/yyyy"
  };

  $.fn.datepicker.dates['de'] = {
    days: ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
    daysShort: ["Son", "Mon", "Die", "Mit", "Don", "Fre", "Sam"],
    daysMin: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
    months: ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
    monthsShort: ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"],
    today: "Heute",
    monthsTitle: "Monate",
    clear: "Löschen",
    weekStart: 1,
    format: "dd.mm.yyyy"
  };
  //FASTBOOKER
  var arrival = $('.js-arrival');
  var departure = $('.js-departure');
  var locale = $('html').attr('lang');
  var fbg = $('.fastbooker__input');

fbg.on('focus', function () {
  $(this).parent().addClass('isActive')
});
fbg.on('blur', function () {
  $(this).parent().removeClass('isActive')
});
  arrival.datepicker({
    format: 'dd MM yyyy',
    language: locale,
    autoclose: true,
    startDate: 'd',
    container: '.f1',
    orientation: 'top left',
    weekStart: 1
  }).on('changeDate', function(e) {
    departure.focus();
    var selectedDate = arrival.datepicker('getDate');
    var nextDay = new Date(selectedDate);
    nextDay.setDate(nextDay.getDate() + 1);
    updateCheckin();
    departure.datepicker('setDate', nextDay);
    departure.datepicker('setStartDate', nextDay);
  });
  departure.datepicker({
    format: 'dd MM yyyy',
    language: locale,
    autoclose: true,
    startDate: tomorrow,
    container: '.f2',
    orientation: 'top left',
    weekStart: 1
  }).on('changeDate', function(e) {
    updateCheckout();
  });

  function updateCheckin() {
    var date = $('.js-arrival').val();
    date = date.split(' ');

    $('.js-checkin-day').text(date[0]);
    $('.js-checkin-month').text(date[1]);
    $('.js-checkin-year').text(date[2]);

  }

  function updateCheckout() {
    var date = $('.js-departure').val();
    date = date.split(' ');
    $('.js-checkout-day').text(date[0]);
    $('.js-checkout-month').text(date[1]);
    $('.js-checkout-year').text(date[2]);

  }



  var btn = $('.js-fastbooker-btn');
  btn.click(function(e) {
    e.preventDefault();
    var arrival = $('#arrival').val();
    arrival = arrival.split(' ');


    var aMonth = $.fn.datepicker.dates[locale].months.indexOf(arrival[1]) + 1;
    var arrivalMonth = ('0' + aMonth).slice(-2);
    arrivalDate = arrival[2] + '-' + arrivalMonth + '-' + arrival[0];

    var departure = $('#departure').val();
    departure = departure.split(' ');

    var dMonth = $.fn.datepicker.dates[locale].months.indexOf(departure[1]) + 1;
    var departureMonth = ('0' + dMonth).slice(-2);

    departureDate = departure[2] + '-' + departureMonth + '-' + departure[0];
    var href = "https://reservations.cubilis.eu/auberge-la-grande-cure-marcourt-la-roche";
    if ((arrival !== 'undefined-undefined-') && (departure !== 'undefined-undefined-')) {
      href = "https://reservations.cubilis.eu/auberge-la-grande-cure-marcourt-la-roche/Rooms/Select?" + "Arrival=" + arrivalDate + "&Departure=" + departureDate + getLangCode(locale);
    }
    window.open(href, '_blank');
  });


  function getLangCode(locale) {
    var langCode = {
      'nl': 'nl-BE',
      'fr': 'fr-BE',
      'en': 'en-GB',
      'de': 'de-DE',
    }[locale];
    return '&Language=' + langCode;
  }

})(jQuery);
