var slick = require( 'slick-carousel' );

var bgSlider = $( '.js-slider' );

bgSlider.slick( {
  arrows: false,
  fade: true,
  dots: true,
  autoplay: true,
  autoplaySpeed: 4000,
  pauseOnHover: false
} );
