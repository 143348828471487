var magnificPopup = require("magnific-popup");

var $gallery = $('.js-gallery');

$gallery.each(function() {
  $(this).magnificPopup({
    delegate: 'a',
    type: 'image',
    gallery: {
      enabled: true
    }
  });
});
